import axios from 'axios';

export const ENDPOINT_USER_CALENDAR_USERS = '/calendar/users';
export const ENDPOINT_USER_TECHNICIAN_NAMES = '/user/technicians_names';
export const ENDPOINT_USER_TECHNICIANS = '/user/technicians';

export default class UserService {
  static async getUsers() {
    const response = await axios.get('users');
    return response.data;
  }

  static async getUsersForWarehouse() {
    const response = await axios.get('users/main-info');
    return response.data;
  }

  static async getUser(id) {
    const response = await axios.get(`users/${id}`);
    return response.data;
  }

  static async createUser(data) {
    const response = await axios.post('users', data);
    return response.data;
  }

  static async updateUser(data) {
    const response = await axios.put(`users/${data.id}`, data);
    return response.data;
  }

  static async deleteUser(id) {
    const response = await axios.delete(`users/${id}`);
    return response.data;
  }

  static async getTechnicians({ signal } = {}) {
    const response = await axios.get(ENDPOINT_USER_TECHNICIANS, {
      signal,
    });
    return response.data;
  }

  static async getTechniciansNames({ signal } = {}) {
    const response = await axios.get(ENDPOINT_USER_TECHNICIAN_NAMES, {
      signal,
    });
    return response.data;
  }

  static async getForCalendar({ signal } = {}) {
    const response = await axios.get(ENDPOINT_USER_CALENDAR_USERS, { signal });
    return response.data;
  }

  static async getAllUsersNames() {
    const response = await axios.get('users/main-info');
    return response.data;
  }

  static async getRoles() {
    const response = await axios.get('user/roles');
    return response.data.data;
  }
}
